import * as React from "react"
import { Link } from "gatsby"


const BlogCard = ({post}) => {
  return (
    <div className="Blog-card">
      <div className="Blog-card__image" style={{backgroundImage: `url('${post.mainImage.url}')`}}>
      </div>
      <div className="Blog-card__content">
        <h3>{post.title}</h3>
        <p>{post.metaDescription}</p>
        <div className="Card__linkWrapper">
          <Link to={`/blog/${post.slug}`} itemProp="url" className="">
            Read More
          </Link>
        </div>
      </div>
    </div>
  )
}



// [:div.Blog-card
//  [:div.Blog-card__image {:style {:background-image (str "url('"(-> post second :img-url)"')")}}]
//  [:div.Blog-card__content
//   [:h3 (-> post second :header :title)]
//   [:p (-> post second :excerpt)]
//   [:div.Card__linkWrapper
//    [:a {:href (str "/blog/" (-> post first name))} "Read More"]]]])
export default BlogCard
