import * as React from "react"
import { Link, useStaticQuery, graphql  } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogCard from "../components/BlogCard"

import Summary from "../components/home/Summary"

import HeaderImage from "../images/main.webp"
import RunningImage from "../images/running.webp"

// main.webp
// <StaticImage
//   src="../images/gatsby-astronaut.png"
//   width={300}
//   quality={95}
//   formats={["auto", "webp", "avif"]}
//   alt="A Gatsby astronaut"
//   style={{ marginBottom: `1.45rem` }}
// />
const Blog = () => {
  const posts = useStaticQuery(query).allStrapiArticle.edges;
  console.log(posts)
  return (
    <Layout>
      <Seo title="Home" />
      <div className="Blog-page SubPage">
        <div className="Subpage__header">
          <h1>Blog</h1>
        </div>
        <div className="Blog-page__content maxWidth flex-row">
          {posts.map((post) => <BlogCard post={post.node} />)}
        </div>
      </div>
    </Layout>
  )
}

const query = graphql`
query {
  allStrapiArticle(sort: {fields: published_at, order: DESC}) {
    edges {
      node {
        title
        content
        metaTitle
        metaDescription
        slug
        mainImage {
          url
        }
        published_at
      }
    }
  }
}
`;

export default Blog
